import { uploadBlobsToS3 } from "./uploadBlobsToS3";
import store from "../store/index";

let chunkRecordingStatus = false;
let chunkAudioMediaRecorder = null;
let chunks = [];
let AUDIO_S3_FOLDER_NAME = "audio_";
let isAudioMuted = false;
let chunkRecordingIntervalId = null;

export const startAudioRecording = (fromSocket) => {
  try {
    if (chunkRecordingStatus) {
      return;
    }
    // console.log("Clicked on start rec1");
    const ts = new Date().getTime();
    const socketId = store.getState().room.participants[0].socketId;
    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      chunkAudioMediaRecorder = new MediaRecorder(stream);
      chunkAudioMediaRecorder.ondataavailable = (e) => {
        if (isAudioMuted) {
          chunkAudioMediaRecorder.stream.getAudioTracks()[0].enabled = false;
        } else {
          chunkAudioMediaRecorder.stream.getAudioTracks()[0].enabled = true;
        }
        chunks.push(e.data);
      };
      chunkAudioMediaRecorder.start(1000);
      chunkRecordingStatus = true;
      const timestamp = store.getState().recorder.AUDIO_S3_FOLDER_NAME;
      if (AUDIO_S3_FOLDER_NAME === "audio_") {
        AUDIO_S3_FOLDER_NAME = AUDIO_S3_FOLDER_NAME + "_" + timestamp;
      }

      chunkAudioMediaRecorder.addEventListener("stop", () => {
        const blob = new Blob(chunks, {
          type: "audio/webm",
        });

        uploadBlobsToS3(
          blob,
          AUDIO_S3_FOLDER_NAME,
          store.getState().room.roomId,
          "audio"
        );
        chunks = [];
        if (chunkRecordingStatus) {
          if (isAudioMuted) {
            chunkAudioMediaRecorder.stream.getAudioTracks()[0].enabled = false;
          } else {
            chunkAudioMediaRecorder.stream.getAudioTracks()[0].enabled = true;
          }
          chunkAudioMediaRecorder.start(500);
        } else {
          AUDIO_S3_FOLDER_NAME = "audio_";
        }
      });
    });
    startChunkRecordingInterval();
  } catch (err) {
    // console.log(err);
  }
};

export const stopAudioChunkUploading = () => {
  if (chunkRecordingStatus) {
    chunkRecordingStatus = false;
    chunkAudioMediaRecorder.stop();
  }
};

export const handleMuteUnmute = (isMuted) => {
  if (chunkRecordingStatus) {
    // console.log(chunkAudioMediaRecorder.stream.getAudioTracks()[0]);
    if (isAudioMuted === isMuted) {
      return;
    }
    isAudioMuted = isMuted;
    if (isMuted) {
      chunkAudioMediaRecorder.stream.getAudioTracks()[0].enabled = false;
    } else {
      chunkAudioMediaRecorder.stream.getAudioTracks()[0].enabled = true;
    }
  }
};


const startChunkRecordingInterval = () => {
  // Call the setInterval function and store the interval ID
  chunkRecordingIntervalId = setInterval(() => {
    if (chunkRecordingStatus) {
      chunkAudioMediaRecorder.stop();
      if (isAudioMuted) {
        chunkAudioMediaRecorder.stream.getAudioTracks()[0].enabled = false;
      } else {
        chunkAudioMediaRecorder.stream.getAudioTracks()[0].enabled = true;
      }
    }
  }, 10000);
};

export const stopChunkRecordingInterval = () => {
  // Clear the setInterval function using its ID
  clearInterval(chunkRecordingIntervalId);
};